@import "../config"
@import "/styles/config"


.achieve_card
  background-color: $white
  display: flex
  flex-direction: column
  gap: 1.5rem
  padding: 2.5rem
  border: 1px solid #E1E7EB
  border-left-width: 0.5px
  transition: background-color .15s ease-in-out

  +max-width($tablet)
    padding: 1.5rem
    gap: 1rem


  &:hover
    &:not(:first-child)

      @media (hover: hover)
        background-color: $violet

        .achieve_place,
        .achieve_number,
        .achieve_description,
        .achieve_source
          color: $white

        .achieve_description
          text-decoration: underline


        .achieve_icon
          filter: invert(1)

  &.overall_card
    padding: 0
    border-width: 0
    gap: 0
    background: transparent

    +max-width($mobail)
      padding-bottom: 1.25rem

    .achieve_place, .achieve_number
      font: 700 7.5rem/120% $font
      color: $color

      +max-width($tablet)
        font-size: 1.75rem

    .achieve_description
      font: 400 1.825rem/140% $font
      color: $color

      +max-width($tablet)
        font-size: 1.15rem


.achieve_place
  font: 400 1.825rem/150% $font
  color: $color
  display: flex
  gap: 0.5rem
  align-items: end

  +max-width($tablet)
    font: 400 1.25rem/140% $font

.achieve_number
  font: 700 4rem/102% $font
  color: $main-color

  +max-width($tablet)
    font: 700 1.75rem/125% $font

.achieve_description
  font: 700 1.825rem/140% $font
  color: $color

  +max-width($tablet)
    font: 700 1.25rem/140% $font

.achieve_source
  margin-top: auto
  display: flex
  gap: 0.5rem
  font: 400 1.5rem/140% $font
  color: $color
  text-transform: uppercase

  +max-width($tablet)
    font: 400 1rem/140% $font


.achieve_icon
  +max-width($tablet)
    width: 1.375rem
    height: 1.375rem

