@import "../config"
@import "/styles/config"

.achieve_wrapper
  background-color: $lightbg


.achieve_card_cist
  display: grid
  grid-template-columns: repeat(3, 1fr)

  +max-width($desktop-mini)
    grid-template-columns: repeat(2, 1fr)

  +max-width($mobail)
    grid-template-columns: 1fr
