@import "../config"
@import "/styles/config"

.title
    padding: 7.5rem 0 120px
    margin: 0
    +max-width($tablet)
        padding: 3.75rem 0 80px
    +max-width($mobail)
        padding: 72px 0 40px


    &-header
        max-width: 65rem
        color: #30343E
        font: 700 64px/120% $font
        margin: 0 0 60px 0
        +max-width($tablet)
            font: 700 52px/120% $font
            margin: 0 15rem 40px 0
        +max-width($mobail)
            font: 700 28px/125% $font
            margin: 0 1rem 32px 0

        span
            display: inline-block
            line-height: 80%
            border-bottom: 7px solid $btn-link
            width: fit-content
            margin: 0
            +max-width($tablet)
                border-bottom: 5px solid $btn-link
            +max-width($mobail)
                border-bottom: 3px solid $btn-link


    &-links
        display: flex
        gap: 2.5rem
        +max-width($tablet)
            gap: 1.75rem
            flex-wrap: wrap
            +max-width($mobail)
                gap: 1.25rem


    &-link__wrapper
        padding: 0 20px
        +max-width($tablet)
            padding: 0 16px
        +max-width($mobail)
            padding: 10px 16px


    &-link
        border: 1px solid $btn-link
        color: $btn-link
        padding: 14px 32px
        text-transform: uppercase
        letter-spacing: 1px
        font: 600 16px/140% $font
        border-radius: 27.5px
        transition: all 0.3s ease
        +max-width($mobail)
            padding: 8px 20px
            letter-spacing: 0.8px
            font: 600 14px/171% $font
            border-radius: 20px

        &:hover
            color: #FFFFFF
            background: $btn-link
